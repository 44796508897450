(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/engine/assets/javascripts/create-wagers.js') >= 0) return;  svs.modules.push('/components/tipsen/engine/assets/javascripts/create-wagers.js');
"use strict";

const {
  flatArray
} = svs.components.lbUtils.flatArray;
const {
  createWager
} = svs.components.payment.createWager;
const {
  selectCoupon,
  selectReductions,
  selectCurrentBetAmount,
  selectRSystem,
  selectUSystem,
  selectSelectedSystemType,
  selectMSigns,
  selectUSigns,
  selectBoardQuickpickVariant,
  selectIsCouponExternal,
  selectExternalSystemChunkSize,
  selectIsEveryMatchSelected,
  selectReduceCount,
  selectCurrentWeek,
  selectCurrentSportkryssFactor,
  selectIsSportkryssSupported,
  selectSportkryssNumber
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  getDefinitionByProduct
} = svs.components.tipsen.engine.utils;
const {
  productIds
} = svs.utils.products;
const {
  selectById
} = svs.components.sport.drawsRedux.globalizedSelectors;
const {
  Reducer
} = svs.components.sport.percentageReduce;
const {
  signDistributionConversion
} = svs.components.tipsen.engine.utils;
const {
  gameBoardSystemType,
  systems
} = svs.components.tipsen.engine.constants;
const {
  EXTERNAL_SYSTEMS
} = svs.components.payment.common.constants.BetProperties;
const {
  getBitmapIndices
} = svs.components.sport.tipsenShared.bitmap;
const convert1x2OutcomesToIndex = ['1', 'X', '2'];
const createWagerScore = (state, couponId, boards) => {
  const coupon = selectCoupon(state, couponId);
  const {
    productId,
    drawNumber
  } = coupon;
  const engineDefinition = getDefinitionByProduct(productId);
  const engineReductionValue = engineDefinition.reduction.reduce((sum, value) => sum + value, 0);
  const betAmount = selectCurrentBetAmount(state, couponId);
  const isExternal = selectIsCouponExternal(state, couponId);
  const wager = createWager().addBet(productId, bet => {
    bet.setDrawNumber(drawNumber);
    bet.setRowPrice(betAmount);
    boards.forEach((board, boardIndex) => {
      let boardReduction;
      let isReduction;
      if (!isExternal) {
        boardReduction = selectReductions(state, couponId, boardIndex);
        isReduction = boardReduction.find(eventReductionValue => eventReductionValue < engineReductionValue);
      }
      if (isExternal) {
        bet.addBetProperty(EXTERNAL_SYSTEMS);
      }
      bet.addBoard(wagerBoard => {
        board.signs.forEach((eventSigns, eventIndex) => {
          if (isReduction) {
            const eventNumber = eventIndex + 1;
            wagerBoard.addReduction(eventNumber, boardReduction[eventIndex]);
          }
          const homeTeamSignsByIndex = getBitmapIndices(eventSigns[0]).flat();
          const awayTeamSignsByIndex = getBitmapIndices(eventSigns[1]).flat();
          wagerBoard.addOutcomes(homeTeamSignsByIndex, awayTeamSignsByIndex);
        });
      });
    });
  });
  return wager;
};
const selectWagersScore = (state, couponId, activatedDrawId, commissionPercent, role) => {
  const coupon = selectCoupon(state, couponId);
  const isExternal = selectIsCouponExternal(state, couponId);
  const wagerChunks = [];
  const boardChunks = [];
  if (isExternal) {
    const externalSystemTotalWagerMaxSize = selectExternalSystemChunkSize(state, couponId);
    for (let i = 0; i < coupon.boards.length; i += externalSystemTotalWagerMaxSize) {
      boardChunks.push(coupon.boards.slice(i, i + externalSystemTotalWagerMaxSize));
    }
  } else {
    boardChunks.push(coupon.boards);
  }
  boardChunks.forEach(boardChunk => {
    const wager = createWagerScore(state, couponId, boardChunk);
    if (activatedDrawId) {
      wager.setRole(role);
      wager.setCommissionPercent(commissionPercent);
      wager.setActivatedDrawId(activatedDrawId);
    }
    wagerChunks.push(wager);
  });
  return wagerChunks;
};
const createWager1x2 = (state, couponId, boards, subProductId) => {
  const coupon = selectCoupon(state, couponId);
  const {
    productId,
    drawNumber
  } = coupon;
  const numberOfDraws = selectCurrentWeek(state, couponId);
  const isExternal = selectIsCouponExternal(state, couponId);
  const systemType = selectSelectedSystemType(state, couponId);
  let system;
  if (systemType === gameBoardSystemType.RSYS) {
    system = selectRSystem(state, couponId);
  }
  if (systemType === gameBoardSystemType.USYS) {
    system = selectUSystem(state, couponId);
  }
  const isReduceFreely = systemType === systems.SYSTEM_REDUCERA_FRITT;
  const betAmount = selectCurrentBetAmount(state, couponId);
  const wager = createWager().addBet(productId, bet => {
    bet.setDrawNumber(drawNumber);
    bet.setRowPrice(betAmount);
    if (subProductId) {
      bet.setSubProductId(subProductId);
    }
    if (isReduceFreely) {
      bet.addBetProperty(systemType);
    }
    if (isExternal) {
      bet.addBetProperty(EXTERNAL_SYSTEMS);
    }
    if (numberOfDraws > 1) {
      bet.setNumberOfDraws(numberOfDraws);
    }
    boards.forEach((board, boardIndex) => {
      const isValidBoard = selectIsEveryMatchSelected(state, couponId, boardIndex);
      if (!isValidBoard) {
        return;
      }
      bet.addBoard(wagerBoard => {
        if (isReduceFreely) {
          board.forEach(eventSigns => {
            wagerBoard.addOutcomes([convert1x2OutcomesToIndex.indexOf(eventSigns)]);
          });
        } else {
          board.signs.forEach(eventSigns => {
            const indexEventSigns = [];
            eventSigns.forEach((sign, i) => {
              if (sign) {
                return indexEventSigns.push(i);
              }
            });
            wagerBoard.addOutcomes(indexEventSigns);
          });
        }
        const pixVariant = selectBoardQuickpickVariant(state, couponId, boardIndex);
        if (pixVariant) {
          wagerBoard.addBoardProperty(pixVariant);
        }
        const mSigns = selectMSigns(state, couponId, boardIndex);
        const uSigns = selectUSigns(state, couponId, boardIndex);
        if (system) {
          wagerBoard.setSystemNumber(system);
          mSigns.forEach((sign, index) => {
            if (sign) {
              wagerBoard.addMathHedge(index + 1);
            }
          });
          uSigns.forEach((value, index) => {
            if (value !== null) {
              wagerBoard.addUOutcome(index + 1, value);
            }
          });
        }
      });
    });
  });
  const currentSportKryssFactor = selectCurrentSportkryssFactor(state, couponId);
  const hasSportKryss = Boolean(selectIsSportkryssSupported(state, couponId) && currentSportKryssFactor > 0);
  if (hasSportKryss) {
    wager.addBet(productIds.SPORTKRYSS, bet => {
      bet.setRowPrice(currentSportKryssFactor);
      if (numberOfDraws > 1) {
        bet.setNumberOfDraws(numberOfDraws);
      }
      const currentSportKryssNumbers = selectSportkryssNumber(state, couponId);
      bet.addBoard(wagerBoard => {
        const sportKryssSigns = currentSportKryssNumbers.split('').map(outcome => parseInt(outcome, 10));
        sportKryssSigns.forEach(sign => {
          wagerBoard.addOutcomes([sign]);
        });
      });
    });
  }
  return wager;
};
const selectWagers1x2 = (state, couponId, activatedDrawId, commissionPercent, role) => {
  var _draw$subProductId;
  const coupon = selectCoupon(state, couponId);
  const isExternal = selectIsCouponExternal(state, couponId);
  const systemType = selectSelectedSystemType(state, couponId);
  const {
    productId,
    drawNumber
  } = coupon;
  const draw = selectById(state, "".concat(productId, "_").concat(drawNumber));
  const subProductId = (_draw$subProductId = draw === null || draw === void 0 ? void 0 : draw.subProductId) !== null && _draw$subProductId !== void 0 ? _draw$subProductId : undefined;
  const wagerChunks = [];
  const boardChunks = [];
  if (isExternal) {
    const externalSystemTotalWagerMaxSize = selectExternalSystemChunkSize(state, couponId);
    for (let i = 0; i < coupon.boards.length; i += externalSystemTotalWagerMaxSize) {
      boardChunks.push(coupon.boards.slice(i, i + externalSystemTotalWagerMaxSize));
    }
  } else if (systemType === systems.SYSTEM_REDUCERA_FRITT) {
    const percentageReducer = new Reducer(signDistributionConversion(coupon.boards[0].signDistribution || []), selectReduceCount(state, couponId));
    boardChunks.push(percentageReducer.reduce());
  } else {
    boardChunks.push(coupon.boards);
  }
  boardChunks.forEach(boardChunk => {
    const wager = createWager1x2(state, couponId, boardChunk, subProductId);
    if (activatedDrawId) {
      wager.setRole(role);
      wager.setCommissionPercent(commissionPercent);
      wager.setActivatedDrawId(activatedDrawId);
    }
    wagerChunks.push(wager);
  });
  return wagerChunks;
};
const createWagerGoalCount = (state, couponId, boards) => {
  const coupon = selectCoupon(state, couponId);
  const {
    productId,
    drawNumber
  } = coupon;
  const isExternal = selectIsCouponExternal(state, couponId);
  const numberOfDraws = selectCurrentWeek(state, couponId);
  const wager = createWager().addBet(productId, bet => {
    bet.setDrawNumber(drawNumber);
    if (isExternal) {
      bet.addBetProperty(EXTERNAL_SYSTEMS);
    }
    if (numberOfDraws > 1) {
      bet.setNumberOfDraws(numberOfDraws);
    }
    boards.forEach((board, boardIndex) => {
      bet.addBoard(wagerBoard => {
        board.signs.forEach(eventSigns => {
          const indexEventSigns = [];
          eventSigns.forEach((sign, i) => {
            if (sign) {
              return indexEventSigns.push(i);
            }
          });
          wagerBoard.addOutcomes(indexEventSigns);
        });
        const pixVariant = selectBoardQuickpickVariant(state, couponId, boardIndex);
        if (pixVariant) {
          wagerBoard.addBoardProperty(pixVariant);
        }
      });
    });
  });
  return wager;
};
const selectWagersGoalCount = (state, couponId, activatedDrawId, commissionPercent, role, addonProductId) => {
  const coupon = selectCoupon(state, couponId);
  const isExternal = selectIsCouponExternal(state, couponId);
  const wagerChunks = [];
  const boardChunks = [];
  if (isExternal) {
    const externalSystemTotalWagerMaxSize = selectExternalSystemChunkSize(state, couponId);
    for (let i = 0; i < coupon.boards.length; i += externalSystemTotalWagerMaxSize) {
      boardChunks.push(coupon.boards.slice(i, i + externalSystemTotalWagerMaxSize));
    }
  } else {
    boardChunks.push(coupon.boards);
  }
  boardChunks.forEach(boardChunk => {
    const wager = createWagerGoalCount(state, couponId, boardChunk);
    if (activatedDrawId) {
      wager.setRole(role);
      wager.setCommissionPercent(commissionPercent);
      wager.setActivatedDrawId(activatedDrawId);
    }
    if (addonProductId) {
      wager.setAddonProductId(addonProductId);
    }
    wagerChunks.push(wager);
  });
  return wagerChunks;
};
setGlobal('svs.components.tipsen.engine.createWagers', {
  selectWagersScore,
  selectWagers1x2,
  selectWagersGoalCount
});

 })(window);