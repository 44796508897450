(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/engine/assets/javascripts/actions.js') >= 0) return;  svs.modules.push('/components/tipsen/engine/assets/javascripts/actions.js');
"use strict";

var _svs$core$config$data, _svs$core$config$data2;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  createAction,
  createAsyncThunk
} = RTK;
const {
  selectCoupon,
  selectIsRelatedCouponValid,
  selectCurrentAddonPixSizeIndex,
  selectBoardUserSigns,
  selectIsEveryMatchSelectedWithUserSigns,
  selectExternalSystemChunkSize,
  selectRelatedDrawCoupon
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  generateSportPixService
} = svs.components.tipsen.engine.services;
const {
  generateSportkryssNumber
} = svs.components.tipsen.engine.utils;
const {
  XPERT_WAGER_RSYS,
  XPERT_WAGER_MSYS
} = svs.components.tipsen.engine.constants.xpertWagerSystemTypes;
const {
  COUPON_SINGLEROWS
} = svs.components.tipsen.engine.constants.couponTypes;
const {
  selectRSystems,
  selectAddonPixSizes
} = svs.components.tipsen.engine.selectors.engineSelectors;
const {
  selectEngineDefinitionByProductId
} = svs.components.tipsen.engine.selectors;
const {
  selectById
} = svs.components.sport.drawsRedux.globalizedSelectors;
const {
  EventTypeId
} = svs.components.sportinfo.common.constants;
const {
  externalSystemsType,
  externalSystemsBoardsType,
  SportPixWagerSystemTypes,
  wagerSystemTypes
} = svs.components.tipsen.engine.constants;
const {
  getLogger
} = svs.core.log;
const {
  jupiter
} = svs.core;
const {
  makeSelectRelatedDraw
} = svs.components.sport.drawsRedux.selectors;
const {
  LoadingKeys
} = svs.components.tipsen.loadingState.constants;
const {
  actions,
  PARAM_ACTION,
  signIndexToOutcome,
  TipsenModuleGameUrl
} = svs.components.sport.tipsenShared;
const {
  transferRowsMW
} = svs.components.paymentMiddlewares.middlewareTransferRows;
const {
  selectWagersScore,
  selectWagersGoalCount,
  selectWagers1x2
} = svs.components.tipsen.engine.createWagers;
const {
  productIds
} = svs.utils.products;
const {
  wagerPaymentFlow
} = svs.components;
const {
  marketplaceFlow,
  executePayment,
  standardFlow
} = wagerPaymentFlow;
const {
  WALLET_MARKETPLACE_RETAILER
} = svs.components.tipsen.wallet.walletType;
const {
  formatDate
} = svs.components.lbUtils.helpers;
const {
  trackEvent
} = svs.components.analytics;
const switchEngine = createAction('tipsen/switchEngine');
const toggleAlternative = createAction('tipsen/toggleAlternative');
const toggleOutcome = createAction('tipsen/toggleOutcome');
const setExternalCoupon = createAction('tipsen/setExternalCoupon');
const selectBetAmount = createAction('tipsen/selectBetAmount');
const setSportkryssFactorIndex = createAction('tipsen/setSportkryssFactorIndex');
const setSportkryssNumber = createAction('tipsen/setSportkryssNumber');
const clearCoupon = createAction('tipsen/clearCoupon');
const clearCouponBoard = createAction('tipsen/clearCouponBoard');
const selectWeek = createAction('tipsen/selectWeek');
const selectPixSize = createAction('tipsen/selectPixSize');
const selectSystem = createAction('tipsen/selectSystem');
const toggleMSign = createAction('tipsen/toggleMSign');
const setCouponValues = createAction('tipsen/setCouponValues');
const resetWithCouponValues = createAction('tipsen/resetWithCouponValues');
const setSignDistribution = createAction('tipsen/setSignDistribution');
const setReduceModeCount = createAction('tipsen/setReduceModeCount');
const toggleReduction = createAction('tipsen/toggleReduction');
const addCouponValues = createAction('tipsen/addCouponValues');
const isSelfService = (_svs$core$config$data = (_svs$core$config$data2 = svs.core.config.data.svsconfig) === null || _svs$core$config$data2 === void 0 ? void 0 : _svs$core$config$data2.isSelfService) !== null && _svs$core$config$data !== void 0 ? _svs$core$config$data : false;
const wagerLogger = getLogger('tipsen:wager');
const _generateSportPixCoupon = async (_ref, _ref2) => {
  let {
    productId,
    drawNumber,
    system,
    boards = [0],
    couponId,
    baseRow,
    keepUserSigns = false,
    xpertAnalysisAuthorId = null,
    overwriteCoupon = true,
    type 
  } = _ref;
  let {
    getState
  } = _ref2;
  let rSystem;
  const state = getState();
  const isSingleRows = type === wagerSystemTypes.SROW;
  let guaranteedEvents = isSingleRows ? {
    rows: []
  } : {};
  const completedRows = []; 
  let filteredArray = boards;
  if (keepUserSigns || baseRow && !isSingleRows) {
    var _definition$outcomes$;
    const definition = selectEngineDefinitionByProductId(productId);
    const eventTypeId = (_definition$outcomes$ = definition === null || definition === void 0 ? void 0 : definition.outcomes.eventTypeId) !== null && _definition$outcomes$ !== void 0 ? _definition$outcomes$ : EventTypeId.EVENT_1X2;
    filteredArray = boards.filter(boardIndex => {
      const isBoardValidWithUserSigns = selectIsEveryMatchSelectedWithUserSigns(state, couponId, boardIndex);
      if (isSingleRows && isBoardValidWithUserSigns) {
        const signs = selectBoardUserSigns(state, couponId, boardIndex);
        completedRows.push({
          index: boardIndex,
          signs
        });
        return false;
      }
      return true;
    });
    const signsForEventType = Object.values(signIndexToOutcome[eventTypeId]);
    for (let boardIndex = 0; boardIndex < filteredArray.length; boardIndex++) {
      let signs;
      if (!isSingleRows && baseRow) {
        signs = baseRow;
      } else {
        signs = selectBoardUserSigns(state, couponId, filteredArray[boardIndex]);
      }
      const guaranteedEvent = {
        events: []
      };
      signs.forEach((signRow, i) => {
        if (signRow.find(sign => sign)) {
          const outcomes = [];
          signRow.forEach((sign, signIndex) => {
            if (sign) {
              outcomes.push({
                value: signsForEventType[signIndex]
              });
            }
          });
          guaranteedEvent.events.push({
            eventNumber: i + 1,
            outcomes
          });
        }
      });
      isSingleRows ? guaranteedEvents.rows.push(_objectSpread({
        pixNumber: guaranteedEvents.rows.length + 1
      }, guaranteedEvent)) : guaranteedEvents = guaranteedEvent;
    }
  }
  if (type === XPERT_WAGER_RSYS) {
    const rSystems = selectRSystems(state);
    if (!rSystems.includes(system)) {
      throw new Error("Not a valid RSYS: ".concat(system, ". Avail: ").concat(rSystems.join(', ')));
    }
    rSystem = system;
  }
  const systemType = SportPixWagerSystemTypes[type];
  const signs = await generateSportPixService({
    productId,
    drawNumber,
    size: system,
    guaranteedEvents,
    systemType,
    numRows: filteredArray.length
  });
  const boardSigns = {};
  filteredArray.forEach((board, index) => {
    boardSigns[board] = signs[index];
  });
  if (completedRows.length) {
    completedRows.forEach(row => {
      boardSigns[row.index] = row.signs;
    });
  }
  return {
    boardSigns,
    couponId,
    keepUserSigns,
    rSystem,
    system: undefined,
    xpertAnalysisAuthorId,
    overwriteCoupon
  };
};
const generateSportPixCoupon = createAsyncThunk('tipsen/generateSportPixCoupon', async (_ref3, _ref4) => {
  let {
    data
  } = _ref3;
  let {
    getState
  } = _ref4;
  const {
    productId,
    drawNumber,
    system,
    type,
    numRows = 1,
    couponId,
    baseRow = [],
    keepUserSigns = false,
    xpertAnalysisAuthorId = null
  } = data;
  const boards = [];
  for (let i = 0; i < numRows; i++) {
    boards.push(i);
  }
  return _generateSportPixCoupon({
    productId,
    drawNumber,
    system,
    type,
    boards,
    couponId,
    baseRow,
    keepUserSigns,
    xpertAnalysisAuthorId,
    overwriteCoupon: true
  }, {
    getState
  });
});
const generateSportPixCouponBoard = createAsyncThunk('tipsen/generateSportPixCoupon', async (_ref5, _ref6) => {
  let {
    productId,
    drawNumber,
    system,
    type,
    boardIndex = 0,
    couponId,
    baseRow = [],
    keepUserSigns = false,
    xpertAnalysisAuthorId = null
  } = _ref5;
  let {
    getState
  } = _ref6;
  return _generateSportPixCoupon({
    productId,
    drawNumber,
    system,
    type,
    boards: [boardIndex],
    couponId,
    baseRow,
    keepUserSigns,
    xpertAnalysisAuthorId,
    overwriteCoupon: false
  }, {
    getState
  });
});
const {
  isMarketplaceWallet
} = svs.components.tipsen.wallet.walletHelpers;
const {
  groupTypes
} = svs.components.marketplaceData.constants;
const getRelatedCoupon = createAsyncThunk('tipsen/getRelatedCoupon', async (_ref7, _ref8) => {
  let {
    relatedCouponId,
    couponId
  } = _ref7;
  let {
    getState,
    dispatch
  } = _ref8;
  const state = getState();
  const coupon = selectCoupon(state, couponId);
  const {
    productId,
    drawNumber
  } = coupon || {};
  const selectedPixIndex = selectCurrentAddonPixSizeIndex(state, relatedCouponId);
  const relatedDraw = makeSelectRelatedDraw(productId, drawNumber, 'PIX')(state) || {};
  if (relatedCouponId && selectedPixIndex && relatedDraw) {
    const pixSizes = selectAddonPixSizes(state);
    try {
      const options = {
        system: pixSizes[selectedPixIndex],
        productId: relatedDraw.productId,
        drawNumber: relatedDraw.drawNumber,
        couponId: relatedCouponId,
        type: XPERT_WAGER_MSYS,
        baseRow: [],
        keepUserSigns: false
      };
      await dispatch(generateSportPixCoupon({
        data: options,
        loadingMeta: {
          key: LoadingKeys.PIX,
          pause: 200
        }
      })).unwrap();
    } catch (err) {
      wagerLogger.info("Error dispatching pix for related coupon: ".concat(err));
    }
  }
});
const getWagerFunc = productId => {
  switch (productId) {
    case productIds.BOMBEN:
    case productIds.MATCHEN:
      return selectWagersScore;
    case productIds.STRYKTIPSET:
    case productIds.EUROPATIPSET:
    case productIds.T8STRYK:
    case productIds.T8EXTRA:
    case productIds.T8:
    case productIds.POWERPLAY:
      return selectWagers1x2;
    case productIds.FULLTRAFF:
      return selectWagersGoalCount;
    default:
  }
};

const placeWager = createAsyncThunk('tipsen/placeWager', async (_ref9, _ref10) => {
  let {
    couponId,
    isTransferable
  } = _ref9;
  let {
    getState,
    dispatch
  } = _ref10;
  let state = getState();
  const coupon = selectCoupon(state, couponId);
  const {
    productId
  } = coupon;
  const relatedCoupon = selectRelatedDrawCoupon(state, couponId);
  let relatedCouponId;
  if (relatedCoupon) {
    const {
      productId: relatedProductId,
      drawNumber: relatedDrawNumber
    } = relatedCoupon;
    relatedCouponId = "".concat(relatedProductId, "_").concat(relatedDrawNumber, "_pix");
  }
  const wagers = [];
  const wallet = state.Wallet.wallets[state.Wallet.activeWallet] || undefined;
  const isMPWallet = isMarketplaceWallet(wallet);
  let activatedDrawId;
  let role;
  let commissionPercent;
  if (isMPWallet) {
    const externalSystemChunkSize = selectExternalSystemChunkSize(state, couponId);
    if (coupon.isExternal && coupon.boards.length > externalSystemChunkSize) {
      const marketPlaceText = 'som läggs för lag (Spela tillsammans)';
      const selectSystem = coupon.boards[0].type;
      const message = selectSystem === externalSystemsBoardsType[externalSystemsType.SINGLE] ? "Externa systemspel ".concat(marketPlaceText, " kan inte best\xE5 av fler \xE4n ").concat(externalSystemChunkSize, " enkelrader.") : "M-system (matematiskt system) ".concat(marketPlaceText, " kan inte best\xE5 av fler \xE4n ").concat(externalSystemChunkSize, " M-system vid externt systemspel.");
      const CustomException = {
        message,
        name: 'customError'
      };
      throw CustomException;
    }
    if (wallet.type === WALLET_MARKETPLACE_RETAILER) {
      activatedDrawId = wallet.meta.activatedDrawId;
      role = groupTypes.RETAILER;
      commissionPercent = wallet.meta.commissionPercent;
    } else {
      activatedDrawId = wallet.meta.activatedDrawId;
      role = groupTypes.PRIVATE;
    }
  }
  let createWagerFunc = getWagerFunc(productId);
  wagers.push(...createWagerFunc(state, couponId, activatedDrawId, commissionPercent, role));
  if (relatedCouponId) {
    await dispatch(getRelatedCoupon({
      relatedCouponId,
      couponId
    }));
    state = getState();
    if (selectIsRelatedCouponValid(state, relatedCouponId)) {
      const relatedCoupon = selectCoupon(state, relatedCouponId);
      const {
        productId: relatedProductId
      } = relatedCoupon;
      createWagerFunc = getWagerFunc(relatedProductId);
      wagers.push(...createWagerFunc(state, relatedCouponId, undefined, undefined, undefined, productId)); 
    }
  }
  let shouldPersistBet;
  const persistBetCallback = persistBet => {
    shouldPersistBet = persistBet;
  };
  const isExternal = coupon.isExternal;
  const queryParams = new URLSearchParams(window.location.search);
  queryParams.set(PARAM_ACTION, actions.PAY);
  const paymentContext = {
    userContext: {
      returnQuery: queryParams
    }
  };
  const sportkryssNumber = generateSportkryssNumber();
  if (isSelfService && wagerPaymentFlow.selfServiceFlow) {
    const regCloseTimes = [];
    const brandLogos = [];
    wagers.forEach(wager => {
      var _selectById;
      const productId = wager.bets[0].productId;
      const drawNumber = wager.bets[0].drawNumber;
      regCloseTimes.push(formatDate.formatDate((_selectById = selectById(state, "".concat(productId, "_").concat(drawNumber))) === null || _selectById === void 0 ? void 0 : _selectById.regCloseTime, true, true));
      brandLogos.push(selectEngineDefinitionByProductId(productId).getBrandLogoByDraw(drawNumber));
    });
    const wagerCount = await wagerPaymentFlow.selfServiceFlow.createSelfServicePaymentFlow({
      wagers,
      regCloseTimes,
      brandLogos
    });
    return {
      wagerCount,
      totalWagerCount: wagers.length,
      sportkryssNumber
    };
  }
  let paymentBuilder;
  if (isMPWallet) {
    const isC2C = Boolean(role === groupTypes.PRIVATE);
    paymentBuilder = marketplaceFlow.createMarketplacePaymentFlow({
      config: {
        haltPaymentOnException: true
      },
      wagers,
      isC2C
    });
  } else {
    const isSingleRows = couponId.includes(COUPON_SINGLEROWS);
    const gameUrlOptions = isSingleRows ? {
      subPath: "/".concat(TipsenModuleGameUrl.SINGLES)
    } : {};
    const extraPostMW = [];
    if (isTransferable) {
      extraPostMW.push(transferRowsMW(gameUrlOptions));
    }
    paymentBuilder = standardFlow.createStandardPaymentFlow({
      config: {
        haltPaymentOnException: true
      },
      wagers,
      extraPostMW,
      callback: isExternal ? undefined : persistBetCallback
    });
  }
  const [result, error] = await executePayment.executePayment(paymentBuilder, paymentContext);
  const successfulWagerClientSerials = result.getTransactionIds();
  if (coupon.guid && coupon.isExternal && successfulWagerClientSerials.length > 0) {
    const options = {
      method: 'PUT',
      path: "/wager/1/ownwager/".concat(coupon.guid, "/paid"),
      data: {
        wagerSerials: successfulWagerClientSerials
      }
    };
    jupiter.call(options, () => {}, err => {
      wagerLogger.error("/wager/1/ownwager/".concat(coupon.guid, "/paid with serials: ").concat(successfulWagerClientSerials, " failed with error"), err);
    });
  }
  if (shouldPersistBet) {
    trackEvent({
      category: 'betslip',
      action: 'saved_row',
      opt_label: wagers[0].getProductBet.productName
    });
  }
  return {
    result,
    error,
    sportkryssNumber,
    relatedCouponId,
    shouldPersistBet
  };
});
setGlobal('svs.components.tipsen.engine.actions', {
  switchEngine,
  toggleAlternative,
  setExternalCoupon,
  selectBetAmount,
  setSportkryssFactorIndex,
  setSportkryssNumber,
  placeWager,
  clearCoupon,
  clearCouponBoard,
  selectWeek,
  selectSystem,
  toggleMSign,
  generateSportPixCoupon,
  generateSportPixCouponBoard,
  setCouponValues,
  resetWithCouponValues,
  setSignDistribution,
  setReduceModeCount,
  toggleReduction,
  selectPixSize,
  toggleOutcome,
  addCouponValues
});

 })(window);