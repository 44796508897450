(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/engine/assets/javascripts/definitions/matchen.js') >= 0) return;  svs.modules.push('/components/tipsen/engine/assets/javascripts/definitions/matchen.js');
"use strict";

let EventTypeId;
let productIds;
let constants;
let brandMapping;
let SportTypes;
if (svs.isServer) {
  const trinidad = require('trinidad-core').core;
  constants = require('../constants.es6');
  productIds = trinidad.components.require('utils', 'products').productIds;
  EventTypeId = require('../../../../../sportinfo/common/assets/javascripts/constants.es6').EventTypeId;
  brandMapping = require('../../../../../lb-utils/brand-mapping/assets/javascripts/brand-mapping.es6');
  SportTypes = trinidad.components.require('sport', 'sport-types').api;
} else {
  EventTypeId = svs.components.sportinfo.common.constants.EventTypeId;
  productIds = svs.utils.products.productIds;
  constants = svs.components.tipsen.engine.constants;
  brandMapping = svs.components.lbUtils.brandMapping.fn;
  SportTypes = svs.components.sport.sportTypes.SportTypes;
}
const matchenDefinition = {
  outcomes: {
    eventTypeId: EventTypeId.RESULT,
    outcomeCount: 56,
    outcomeGroupCount: 2,
    outcomeHeatmap: {
      [SportTypes.Undefined]: {
        minValue: 0,
        minStartValue: 0,
        maxValue: 56,
        maxStartValue: 56
      },
      [SportTypes.Soccer]: {
        minValue: 0,
        minStartValue: 0,
        maxValue: 17,
        maxStartValue: 17
      },
      [SportTypes.Hockey]: {
        minValue: 0,
        minStartValue: 0,
        maxValue: 17,
        maxStartValue: 17
      },
      [SportTypes.Bandy]: {
        minValue: 0,
        minStartValue: 0,
        maxValue: 29,
        maxStartValue: 17
      },
      [SportTypes.Handball]: {
        minValue: 7,
        minStartValue: 19,
        maxValue: 48,
        maxStartValue: 36
      },
      [SportTypes.Floorball]: {
        minValue: 0,
        minStartValue: 0,
        maxValue: 29,
        maxStartValue: 17
      }
    }
  },
  competitions: [],
  rSystems: [],
  uSystems: [],
  reduction: [constants.ReductionScore.HOME, constants.ReductionScore.DRAW, constants.ReductionScore.AWAY],
  systems: [constants.systems.SYSTEM_MSYS, constants.systems.SYSTEM_RSYS],
  betAmounts: [10, 20, 50, 100],
  maxBetAmount: 500,
  weeks: [1],
  sportkryssFactors: [0],
  sportkryssBase: 10,
  addonPixSize: [0],
  maxSystemSize: 0,
  drawProductIds: [productIds.MATCHEN],
  xpertMsys: [],
  xpertMsysSpecialValues: [],
  quickPlayPixSizes: [20, 30, 40, 50, 60, 80, 100, 150, 200],
  displayName: brandMapping.getProductDisplayName(productIds.MATCHEN),
  brandName: brandMapping.getSiteBrand(productIds.MATCHEN),
  brandLogo: brandMapping.getProductBrand(productIds.MATCHEN),
  getDisplayNameByDraw: draw => brandMapping.getProductDisplayNameByDraw(draw, productIds.MATCHEN),
  getBrandNamesByDraw: draw => brandMapping.getProductBrandsByDraw(draw, productIds.MATCHEN),
  getBrandLogoByDraw: draw => brandMapping.getProductBrandByDraw(draw, productIds.MATCHEN),
  getBrandMenuColorByDraw: draw => brandMapping.getProductBrandMenuColorByDraw(draw, productIds.MATCHEN),
  maxExternalSystemSize: 0,
  features: {
    marketplace: false,
    singles: false,
    marketplaceTeam: false
  },
  getRelatedProduct: () => null,
  getTransfer: () => null,
  pixSystems: [],
  minGroupAmount: 0,
  minStake: 10,
  hasGuaranteedJackpot: false,
  hasJackpot: false,
  tipsinfoSupport: [constants.TipsinfoTypes.SvenskaFolket],
  tipsinfoSupportResult: [constants.TipsinfoTypes.SvenskaFolket],
  tipsinfoSupportMyBets: [constants.TipsinfoTypes.SvenskaFolket],
  loadDrawsApiPath: 'matchen',
  moduleEngine: 'matchen',
  defaultCouponValues: {
    betAmount: 10,
    reduceModeCount: undefined,
    pixSize: 60
  },
  pixType: 'BombenMatchen',
  pixSystem: '',
  externalChunkSize: {
    SROW: 0,
    MSYS: 0,
    SROW_MARKETPLACE_GROUP: 0,
    MSYS_MARKETPLACE_GROUP: 0
  },
  couponSettings: [],
  maxWagerAmount: 5000
};
if (svs.isServer) {
  module.exports = matchenDefinition;
} else {
  setGlobal('svs.components.tipsen.engine.definitions.matchen', matchenDefinition);
}

 })(window);